<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="bloc-item q-mb-sm q-mt-md">
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-select class="col" v-bind="formInputProps('region_id')" :color="color"
                v-model="formData.region_id" />
              <base-input-select class="col" v-bind="formInputProps('type_aide_id')" :color="color"
                v-model="formData.type_aide_id" />
            </div>
            <div class="row">
              <div class="col-6">
                <base-input-select v-bind="formInputProps('millesime_id')" :color="color"
                  v-model="formData.millesime_id" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6 process-color">{{$t('processes.enveloppeBudgetaire')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <div v-if="currentSuiviDesAidesAdminProcess">
            <div v-for="enveloppe in linkedEnveloppes" :key="enveloppe.id" >
              <q-card flat bordered class="q-pa-sm q-mb-md">
                <q-card-section>
                  <div class="q-gutter-sm row">
                    <base-input-text class="col q-pb-none" :label="$t('processes.fields.montant_enveloppe.label')" :readonly="true" placeholder="Description" :color="color"
                      :value="`${enveloppe.montant}`" />
                    <base-input-text class="col q-pb-none" :label="$t('processes.fields.date_affectation.label')" :readonly="true" :color="color"
                      :value="$formatDate(enveloppe.date_affectation)" />
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </div>
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-text class="col q-pb-none" :label="$t('processes.fields.montant_enveloppe.label')" placeholder="Description" :color="color"
                  v-model="enveloppe.montant_enveloppe" />
                <base-input-date class="col" :label="$t('processes.fields.date_affectation.label')" placeholder="Date" :color="color"
                  v-model="enveloppe.date_affectation" />
              </div>
              <div class="row justify-end">
                <div class="col-3 q-py-sm">
                  <q-btn class="full-width justify-between" color="process" :disabled="isAddEnveloppeDisabled" size="sm" @click="addEnveloppe" icon-right="add_circle">{{$t('processes.enregistrer')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'

export default {
  mixins: [BaseForm],
  components: { },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      isUpdate: this.$route.params.id,
      linkedEnveloppes: [],
      enveloppe: {},
      isAddEnveloppeDisabled: !this.$route.params.id
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviDesAidesAdminProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    })
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'aide-admin'
        if (!newVal || !this.currentSuiviDesAidesAdminProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === this.currentSuiviDesAidesAdminProcess[id]) : this.currentSuiviDesAidesAdminProcess[id]
            }), {})
        }

        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id
          this.linkedEnveloppes = this.currentSuiviDesAidesAdminProcess.enveloppes
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_des_aides_admin'), to: { name: 'suivi-des-aides-admin-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-des-aides-admin-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_des_aides_admin'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-des-aides-admin-form', label: 'Informations', to: { name: 'suivi-des-aides-admin-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'aide-admin' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'aideAdmin')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'aideAdmin')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      this.isAddEnveloppeDisabled = false
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-des-aides-admin-form', params: { id: id.aideAdmin } })
    },
    addEnveloppe () {
      this.linkedEnveloppes.push({ montant: this.enveloppe.montant_enveloppe, date_affectation: this.enveloppe.date_affectation })
      this.isAddEnveloppeDisabled = true
      this.formData = { ...this.formData, ...this.enveloppe }
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'aide-admin', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
